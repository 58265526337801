import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import { InputBase, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ReactGA from "react-ga";
import { useCart } from "react-use-cart";
import { formatToBRL } from 'brazilian-values';
import AOS from 'aos';

import { ProfileType } from "../../interfaces/user.interface";
import { getCatalogBySlug } from "../../services/FirebaseService";
import BottomCart from "../../components/BottomCart";
import { setLocalStorageJson, readLocalStorageJson, PROFILE_OWNER } from "../../utils/localStorage";
import NavbarCatalog from "../../components/NavbarCatalog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(5),
    },
    card: {
      maxWidth: 345,
      width: '10em',
      height: theme.spacing(37),
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      width: "75%"
    },
    title: {
      flexGrow: 1,
      display: "block"
    },
    iconButton: {
      padding: 10
    }
  })
);


export default function Catalog() {
  const classes = useStyles();
  const { addItem, updateItemQuantity, inCart, getItem } = useCart();
  const profileStorage: ProfileType = readLocalStorageJson(PROFILE_OWNER)
  const [profile, setProfile] = useState<ProfileType | null>(profileStorage);
  const [searchWord, setSearchWord] = useState<any>("");
  const [, slug] = window.location.pathname.split("/catalogo/");

  const startCatalogProductsBySlug = async () => {
    const profileData: ProfileType | null = await getCatalogBySlug(slug);
    if (profileData) {
      setProfile(profileData);
      setLocalStorageJson(PROFILE_OWNER, profileData)
    }
  };

  useEffect(() => {
    AOS.init({ duration: 2000 });
    startCatalogProductsBySlug();
  }, []);

  useEffect(() => {
    ReactGA.pageview(slug);
  }, [slug]);

  if (!profile?.uid) return <Paper style={{ margin: 8, padding: 8 }} />;

  return (
    <>
      <NavbarCatalog profile={profile} />
      <Paper style={{ margin: 8, padding: 8, marginTop: 80 }}>
        <div style={{ textAlign: "center" }}>
          <Typography variant="h6" component="h6" color="textSecondary">
            {profile.companyDescription}
          </Typography>
        </div>
        <div
          style={{
            margin: 40,
            borderRadius: 8,
            backgroundColor: "rgba(0, 0, 0, 0.15)"
          }}
        >
          <IconButton
            type="submit"
            className={classes.iconButton}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
          <InputBase
            className={classes.input}
            placeholder="Pesquisar ..."
            inputProps={{ "aria-label": "search google maps" }}
            onChange={event => {
              setSearchWord(event.target.value.toLowerCase());
            }}
          />
        </div>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={2}>
              {profile?.catalog?.map((product) => {
                const hiddenCard =
                  product.name.toLowerCase().search(searchWord) >= 0;
                const productId = `productId-${product.id}`;
                const productIsInCart = inCart(productId);
                const productItem = getItem(productId);

                return (
                  <Grid data-aos="fade-up" key={productId} item hidden={!hiddenCard}>
                    <Card className={classes.card}>
                      <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        image={product.image}
                        title="Contemplative Reptile"
                        style={{ maxHeight: 160 }}
                      />
                      <CardContent>
                        <Typography variant="body2" component="p">
                          {product.name}
                        </Typography>
                        <Typography variant="body2" component="p">
                          {formatToBRL(product.price)}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          color="primary"
                          onClick={() => { updateItemQuantity(productId, productItem.quantity - 1); }}
                          disabled={!productIsInCart}
                        >
                          -
                        </Button>
                        <Typography variant="body2" component="p">
                          {productItem?.quantity || 0}
                        </Typography>
                        <Button
                          color="primary"
                          onClick={() => {
                            if (productIsInCart) {
                              updateItemQuantity(productId, productItem.quantity + 1);
                            } else {
                              addItem({ ...product, id: productId });
                            }
                          }}
                        >
                          +
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <BottomCart />
    </>
  );
}
