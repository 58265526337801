import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import PanoramaFishEyeIcon from "@material-ui/icons/PanoramaFishEye";
import StoreCartIcon from "@material-ui/icons/Store";
import PersonIcon from "@material-ui/icons/Person";
import WebIcon from "@material-ui/icons/Web";
import ArrowBack from "@material-ui/icons/ArrowBack";

import RegisterProducts from "../RegisterProducts";
import Register from "../Register";
import { currentUser } from "../../services/FirebaseAufh";
import {
  setLocalStorageJson,
  readLocalStorageJson
} from "../../utils/localStorage";
import { ProfileType } from "../../interfaces/user.interface";

// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {"Gcode © "}
//       <Link color="inherit" href="https://material-ui.com/">
//         gcodetec.com.br
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    paddingTop: "10vh",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    paddingTop: "10vh",
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  fixedHeight: {
    height: 240
  }
}));

export default function Dashboard() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [screen, setScreen] = React.useState("RegisterProducts");
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton)}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Dashboard
          </Typography>
          {/* <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
        </Toolbar>
      </AppBar>
      <Drawer
        // variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
        }}
        open={open}
        onBackdropClick={handleDrawerOpen}
        onClick={handleDrawerOpen}
      >
        <List>
          <ListItem onClick={() => setScreen("RegisterProducts")} button>
            <ListItemIcon>
              <StoreCartIcon />
            </ListItemIcon>
            <ListItemText primary="Meu Catálogo" />
          </ListItem>
          <ListItem onClick={() => setScreen("Register")} button>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Meus Dados" />
          </ListItem>
          <ListItem
            onClick={async () => {
              const userData = await currentUser();
              if (userData && userData.slug) {
                window.location.href = `/catalogo/${userData.slug}`;
              }
            }}
            button
          >
            <ListItemIcon>
              <WebIcon />
            </ListItemIcon>
            <ListItemText primary="Ver meu Site" />
          </ListItem>
          <ListItem
            onClick={async () => {
              const {
                slug,
                companyDescription,
                companyName
              }: ProfileType = await readLocalStorageJson("user");

              const navigatorWeb = navigator as any;
              if (navigatorWeb && navigatorWeb.share) {
                navigatorWeb
                  .share({
                    title: companyName,
                    url: `/catalogo/${slug}`,
                    text: companyDescription
                  })
                  .then(() => {
                    console.log("Thanks for sharing!");
                  })
                  .catch(console.error);
              } else {
                window.location.href = `/catalogo/${slug}`;
              }
            }}
            button
          >
            <ListItemIcon>
              <PanoramaFishEyeIcon />
            </ListItemIcon>
            <ListItemText primary="Compartilhar meu Site" />
          </ListItem>

          <ListItem
            onClick={async () => {
              await setLocalStorageJson("user", {});
              window.location.href = "/";
            }}
            button
          >
            <ListItemIcon>
              <ArrowBack />
            </ListItemIcon>
            <ListItemText primary="Sair" />
          </ListItem>
        </List>
        <Divider />
      </Drawer>
      <main className={classes.content}>
        <Container maxWidth="lg">
          <Grid container>
            {/* Chart */}
            {/* <Grid item xs={12} md={8} lg={9}>
              <Paper className={fixedHeightPaper}>
                <Chart />
              </Paper>
            </Grid> */}
            {/* Recent Deposits */}
            {/* <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <Deposits />
              </Paper>
            </Grid> */}
            {/* Recent Orders */}
            <Grid item xs={12}>
              {/* <Orders /> */}
              {/* <AdvancedGridList /> */}
              {screen === "Register" && <Register />}
              {screen === "RegisterProducts" && <RegisterProducts />}
            </Grid>
          </Grid>
          {/* <Box pt={4}>
            <Copyright />
          </Box> */}
        </Container>
      </main>
    </div>
  );
}
