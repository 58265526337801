import React, { Component } from "react";
import { firebaseStorage } from "../../utils/firebaseUtils";

class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      url: "",
      progress: 0
    };
  }

  handleChange = async e => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      await this.setState(() => ({ image }));
      this.handleUpload();
    }
  };

  handleUpload = () => {
    const { image } = this.state;
    const uploadTask = firebaseStorage.ref(`images/${image.name}`).put(image);
    uploadTask.on(
      "state_changed",
      snapshot => {
        // progress function ...
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progress });
      },
      error => {
        // Error function ...
        console.log(error);
      },
      () => {
        // complete function ...
        firebaseStorage
          .ref("images")
          .child(image.name)
          .getDownloadURL()
          .then(url => {
            this.props.getFirestoreUrl(url);
            this.setState({ url });
          });
      }
    );
  };

  render() {
    return (
      <div className="center">
        <div className="file-field input-field">
          <input type="file" onChange={this.handleChange} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: 8
          }}
        >
          <img
            src={
              this.state.url ||
              this.props.url ||
              "https://via.placeholder.com/400x300"
            }
            alt="Uploaded Images"
            height="220"
            width="220"
          />
          <progress
            value={this.state.progress}
            max="100"
            style={{ width: "220px" }}
            className="progress"
          />
        </div>
      </div>
    );
  }
}

export default ImageUpload;
