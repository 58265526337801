import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Skeleton from "@material-ui/lab/Skeleton";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import ImageUpload from "../../components/UploadImage";
import { ProductType } from "../../interfaces/products.interface";
import { saveCatalog, getMyCatalog } from "../../services/FirebaseService";
import { readLocalStorageJson } from "../../utils/localStorage";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "94vh"
  },
  typographContainer: {
    position: "absolute",
    color: "white",
    padding: 20,
    height: "100%",
    background: "black",
    opacity: 0.6
  },
  paper: {
    margin: theme.spacing(8),
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    margin: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(1, 0, 2)
  }
}));

export default function RegisterProducts() {
  const classes = useStyles();

  const [selectedProducts, setSelectedProducts] = useState<ProductType[]>();
  const [alertActived, setAlertActived] = React.useState({
    severity: "success",
    text: "",
    open: false
  } as {
    severity: "success" | "error" | "info" | "warning" | undefined;
    text: string;
    open: boolean;
  });
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertActived({ ...alertActived, open: false });
  };

  function createNewProduct() {
    if (
      selectedProducts?.length &&
      !selectedProducts[selectedProducts?.length - 1].image
    ) {
      return setAlertActived({
        text: "Seu último produto não tem imagem",
        severity: "info",
        open: true
      });
    }

    if (selectedProducts) {
      setSelectedProducts([
        ...selectedProducts,
        {
          id: selectedProducts?.length,
          name: "",
          enabled: true,
          image: "",
          cod: "",
          description: "",
          price: 0
        }
      ]);
    }
  }

  async function checkOldProducts() {
    const catalog = await getMyCatalog();
    setSelectedProducts(
      catalog || [
        { id: 0, name: "", enabled: true, image: "", description: "", cod: "", price: 0 }
      ]
    );
  }

  useEffect(() => {
    checkOldProducts();
  }, []);

  return (
    <>
      <div>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          component={Paper}
          elevation={6}
          square
        >
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Inclua os seus produtos no catálogo
            </Typography>

            {selectedProducts !== undefined
              ? selectedProducts.map((item, index) => {
                return (
                  <Grid
                    key={item.id}
                    alignItems="center"
                    alignContent="center"
                    justify="space-around"
                    style={{ marginBottom: 40 }}
                    container
                    spacing={2}
                  >
                    <Grid container item xs={12} sm={12} md={4}>
                      <ImageUpload
                        url={item.image}
                        getFirestoreUrl={(url: string) => (item.image = url)}
                      />
                    </Grid>
                    <Grid container item xs={12} sm={12} md={4}>
                      <TextField
                        disabled={!item.enabled}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id={`price-${index}`}
                        label="Preço do produto"
                        name={`price-${index}`}
                        type="number"
                        onChange={event => {
                          item.price = Number(event.target.value);
                        }}
                        defaultValue={item.price}
                      />

                    </Grid>
                    <Grid container item xs={12} sm={12} md={4}>
                      <TextField
                        disabled={!item.enabled}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id={`name-${index}`}
                        label="Nome do produto"
                        name={`name-${index}`}
                        autoComplete="name"
                        onChange={event => {
                          item.name = event.target.value;
                        }}
                        defaultValue={item.name}
                      />

                    </Grid>
                    <Grid container item xs={12} sm={12} md={1}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <Button
                          onClick={() => {
                            saveCatalog(
                              readLocalStorageJson("user"),
                              selectedProducts
                            );
                            setAlertActived({
                              text:
                                "Seu catálogo foi atualizado com sucesso!",
                              severity: "success",
                              open: true
                            });
                          }}
                        >
                          Salvar
                          </Button>
                        <Button
                          style={{ marginLeft: 16 }}
                          onClick={() => {
                            setSelectedProducts(
                              selectedProducts.filter(
                                item => item.id !== selectedProducts[index].id
                              )
                            );
                          }}
                        >
                          Excluir
                          </Button>
                      </div>
                    </Grid>
                  </Grid>
                );
              })
              : [1, 2, 3].map(item => (
                <Grid
                  key={item}
                  alignItems="center"
                  alignContent="center"
                  container
                  spacing={4}
                  style={{ marginTop: 8 }}
                >
                  <Grid item xs={12} sm={12} md={5}>
                    <Skeleton variant="rect" height={220} width={"100%"} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Skeleton height={50} width="100%" />
                    <Skeleton height={50} width="100%" />
                    <Skeleton height={50} width="100%" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={1}>
                    <Skeleton variant="circle" width={40} height={40} />
                  </Grid>
                </Grid>
              ))}

            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.submit}
                  onClick={createNewProduct}
                >
                  Novo produto
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  className={classes.submit}
                  color="secondary"
                  onClick={() => {
                    if (
                      selectedProducts?.length &&
                      !selectedProducts[selectedProducts?.length - 1].image
                    ) {
                      return setAlertActived({
                        text: "Seu último produto não tem imagem",
                        severity: "info",
                        open: true
                      });
                    }
                    selectedProducts && saveCatalog(readLocalStorageJson("user"), selectedProducts);
                    setAlertActived({
                      text: "Seu catálogo foi salvo com sucesso!",
                      severity: "success",
                      open: true
                    });
                  }}
                >
                  Salvar Todos os produtos
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </div>
      <Snackbar
        open={alertActived.open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={alertActived.severity || "error"}
        >
          {alertActived.text}
        </MuiAlert>
      </Snackbar>
    </>
  );
}
