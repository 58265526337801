import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useCart } from "react-use-cart";
import { useForm } from "react-hook-form";
import ReactGA from "react-ga";

import { ClientType, ProfileType } from "../../interfaces/user.interface";
import { checkoutWhatsappMessage } from "../../utils/general";
import { CartType } from "../../interfaces/products.interface";
import { readLocalStorageJson, setLocalStorageJson, PROFILE_OWNER } from "../../utils/localStorage";
import NavbarCatalog from "../../components/NavbarCatalog";
import history from "../../history";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "94vh"
  },
  typographContainer: {
    position: "absolute",
    color: "white",
    padding: 20,
    height: "100%",
    background: "black",
    opacity: 0.6
  },
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    margin: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(1, 0, 2)
  }
}));

export default function RegisterProducts() {
  const { items, cartTotal } = useCart();
  const classes = useStyles();
  const { register, handleSubmit } = useForm();

  const profile: ProfileType = readLocalStorageJson(PROFILE_OWNER)
  const clientDefault: ClientType = readLocalStorageJson("clientStorage")

  const onSubmit = (client: any) => {
    setLocalStorageJson("clientStorage", client)
    const message = checkoutWhatsappMessage(
      profile,
      client as ClientType,
      items as CartType[],
      cartTotal,
    )
    async function buttonActive() {
      await ReactGA.event({
        category: "Whatsapp",
        action: "finalizarPedido",
        label: `${profile?.slug}_finalizarPedido`
      });
      window.location.href = `https://api.whatsapp.com/send?phone=+55${profile.phone}&text=${message}`
    }
    buttonActive()
  };

  return (
    <>
      <NavbarCatalog profile={profile} />
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 72 }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          component={Paper}
          elevation={6}
          square
        >
          <div className={classes.paper}>
            <Grid container>
              <Grid
                alignItems="center"
                alignContent="center"
                justify="space-around"
                style={{ marginBottom: 40 }}
                container
                spacing={2}
              >
                <Typography
                  variant="h5"
                  align="center"
                  color="textSecondary"
                  component="p"
                >
                  Dados Pessoais
              </Typography>
                <Grid container item xs={12} sm={12} md={4}>
                  <TextField
                    inputRef={register}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Nome"
                    name="name"
                    defaultValue={clientDefault?.name}
                    autoComplete="name"
                  />
                  <TextField
                    inputRef={register}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Telefone"
                    name="phone"
                    defaultValue={clientDefault?.phone}
                    autoComplete="phone"
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div className={classes.paper}>
            <Grid container>
              <Grid
                alignItems="center"
                alignContent="center"
                justify="space-around"
                style={{ marginBottom: 40 }}
                container
                spacing={2}
              >
                <Typography
                  variant="h5"
                  align="center"
                  color="textSecondary"
                  component="p"
                >
                  Endereço
              </Typography>
                <Grid container item xs={12} sm={12} md={4}>
                  <TextField
                    inputRef={register}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Cep"
                    name="cep"
                    defaultValue={clientDefault?.cep}
                    autoComplete="cep"
                    type="number"
                  />
                  <TextField
                    inputRef={register}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Endereço"
                    name="address"
                    defaultValue={clientDefault?.address}
                    autoComplete="address"
                  />
                  <TextField
                    inputRef={register}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Número"
                    name="number"
                    defaultValue={clientDefault?.number}
                    autoComplete="number"
                    type="number"
                  />
                  <TextField
                    inputRef={register}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Bairro"
                    name="neighborhood"
                    defaultValue={clientDefault?.neighborhood}
                    autoComplete="neighborhood"
                  />
                  <TextField
                    inputRef={register}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Complemento"
                    name="complement"
                    defaultValue={clientDefault?.complement}
                    autoComplete="complement"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              color="secondary"
            >
              Finalizar Pedido
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={() => history.goBack()}
              style={{ margin: 20 }}
            >
              Voltar
            </Button>
          </div>
        </Grid>
      </form>
    </>
  );
}
