import { firebaseAuth, firebaseFirestore } from "../utils/firebaseUtils";
import { ProfileType } from "../interfaces/user.interface";
import history from "../history";
import {
  setLocalStorageJson,
  readLocalStorageJson,
} from "../utils/localStorage";
import { string_to_slug } from "../utils/general";

export const signUp = async (
  email: string,
  password: string,
  profileData: ProfileType
) => {
  const auth = await firebaseAuth.createUserWithEmailAndPassword(
    email,
    password
  );

  const slug = string_to_slug(profileData?.companyName || "");
  if (auth.user && auth.user.uid && profileData) {
    const userDat = {
      ...profileData,
      slug,
      uid: auth.user.uid,
    } as ProfileType;

    await setUserData(auth.user.uid, userDat);
    await setLocalStorageJson("user", userDat);
    history.push("/dashboard");
  }
  return auth;
};

export const signIn = async (email: string, password: string) => {
  const { user } = await firebaseAuth.signInWithEmailAndPassword(
    email,
    password
  );

  if (user) {
    const userData = await readUserData(user.uid);
    await setLocalStorageJson("user", userData);
    history.push("/dashboard");
  }
};

export const signOut = async () => {
  await firebaseAuth.signOut();

  await setLocalStorageJson("user", {});
  history.push("/");
};

export const currentUser = async () => {
  const userData = await readLocalStorageJson("user");
  // console.log("user: ", userData);
  return userData as ProfileType;
};

export const updateUserData = async (uid: string, userProfile: ProfileType) => {
  const userUpdated = await firebaseFirestore
    .doc(`users/${uid}`)
    .update({ ...userProfile });
  await setLocalStorageJson("user", userProfile);
  return userUpdated;
};
export const setUserData = async (uid: string, userProfile: ProfileType) => {
  const userUpdated = await firebaseFirestore
    .doc(`users/${uid}`)
    .set({ ...userProfile });
  await setLocalStorageJson("user", userProfile);
  return userUpdated;
};

export const readUserData = async (uid: string) => {
  const user = await firebaseFirestore.doc(`users/${uid}`).get();
  return user.data() as ProfileType;
};

export const handleAuthErrors = (error: { code: string }) => {
  switch (error.code) {
    case "auth/invalid-email":
      return "Email Inválido.";
    case "auth/email-already-in-use":
      return "Esse e-mail ja está sendo usado.";
    case "auth/user-disabled":
      return "Usuário desabilitado.";
    case "auth/user-not-found":
      return "Usuário não encontrado.";
    case "auth/wrong-password":
      return "Senha errada.";
    default:
      console.log(error);
      return "Erro inesperado";
  }
};
