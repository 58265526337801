import React from "react";
import Typography from "@material-ui/core/Typography";
import { ProfileType } from "../../interfaces/user.interface";
import { AppBar, Toolbar } from "@material-ui/core";
import history from "../../history";

export default function NavbarCatalog({ profile }: { profile: ProfileType }) {
    return (
        <AppBar position="fixed" color="default">
            <Toolbar>
                <Typography
                    style={{
                        flexGrow: 1,
                        display: "block"
                    }}
                    variant="h6"
                    onClick={() => {
                        history.push(`/catalogo/${profile?.slug}`)
                    }}
                >
                    {profile?.companyName}
                </Typography>
                {(profile && (
                    <img alt="logo" src={profile.companyPhoto} width="36" />
                )) || <div style={{ height: 36 }} />}
            </Toolbar>
        </AppBar>
    );
}

