import React, { useState, useEffect } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  signIn,
  handleAuthErrors,
  currentUser
} from "../../services/FirebaseAufh";
import history from "../../history";
import { setLocalStorageJson } from "../../utils/localStorage";

const useStyles = makeStyles(theme => ({
  root: {
    height: "94vh"
  },
  typographContainer: {
    position: "absolute",
    color: "white",
    padding: 20,
    height: "100%",
    background: "black",
    opacity: 0.6
  },
  image: {
    backgroundImage:
      "url(http://www.aprdesigners.com.br/wp-content/uploads/2019/10/criacao-de-catalogo-de-autopecas-iguacu.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100%"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function LandingPage() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [showLoginPage, setShowLoginPage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  function lemeRedirect() {
    if (
      "https://catalogo.lememilitar.com/" === window.location.href ||
      "https://catalogo.lememilitar.com.br/" === window.location.href
    ) {
      history.push("/catalogo/leme-militar");
    }
  }

  async function getCurrentUser() {
    const userData = await currentUser();
    if (userData.uid) {
      await setLocalStorageJson("user", userData);
      history.push("/dashboard");
    }
  }

  useEffect(() => {
    getCurrentUser();
    lemeRedirect();
  });

  async function doLogin() {
    setIsLoading(true)
    if (showLoginPage) {
      if (email && password) {
        try {
          await signIn(email, password);
        } catch (error) {
          alert(handleAuthErrors(error));
        }
      } else {
        alert("Preencha email e senha para continuar");
      }
    }
    setIsLoading(false)
  }

  function doRegister() {
    if (!showLoginPage && email && name) {
      history.push(`/register?email=${email}&name=${name}`, {
        email,
        name
      });
    }
  }

  return (
    <Grid
      container
      alignContent="center"
      justify="center"
      component="main"
      className={classes.root}
    >
      <CssBaseline />
      {/* <Grid item xs={false} sm={4} md={7}>
        <div style={{ position: "relative", height: "100%" }}>
          <div className={classes.typographContainer}>
            <Typography variant="h4" color="inherit" align="center" noWrap>
              CRIE SEU CATÁLOGO ONLINE!
            </Typography>
            <Typography variant="h6" color="inherit" align="center">
              Envie para seus clientes no Whatsapp e Facebook, facilite seus
              pedidos, e ganhe visibilidade em todo brasil!
            </Typography>
          </div>
          <img className={classes.image} />
        </div>
      </Grid> */}

      <Grid item xs={12} sm={6} md={6} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Crie seu catálogo
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={event => setEmail(event.target.value)}
            />
            {!showLoginPage && (
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="name"
                label="Nome"
                id="name"
                onChange={event => setName(event.target.value)}
              />
            )}
            {showLoginPage && (
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={event => setPassword(event.target.value)}
              />
            )}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={showLoginPage ? doLogin : doRegister}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress /> : showLoginPage ? "Entrar" : "Criar catálogo"}
            </Button>
            <Button
              fullWidth
              variant="contained"
              className={classes.submit}
              color="secondary"
              onClick={() => setShowLoginPage(!showLoginPage)}
              disabled={isLoading}
            >
              {showLoginPage ? "Cadastre-se" : "Fazer login"}
            </Button>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
