import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
// import { AppBar, Toolbar, Typography } from "@material-ui/core";
import { grey, amber } from "@material-ui/core/colors";
import Landing from "./screens/Landing";
import history from "./history";
import Register from "./screens/Register";
import Dashboard from "./screens/Dashboard";
import Pricing from "./screens/Pricing";
import Checkout from "./screens/Checkout";
import Catalog from "./screens/Catalog";
import ReactGA from 'react-ga';
import { CartProvider } from "react-use-cart";


function initializeReactGA() {
  ReactGA.initialize('UA-137987007-1');
}

const theme = createMuiTheme({
  palette: {
    primary: grey,
    secondary: amber
  }
});

const App: React.FC = () => {
  React.useEffect(() => { initializeReactGA() }, [])
  return (
    <ThemeProvider theme={theme}>
      <CartProvider>
        <Router history={history}>
          <Switch>
            <Route path="/" exact>
              <Landing />
            </Route>
            <Route path="/dashboard">
              <Dashboard />
            </Route>
            <Route path="/pricing">
              <Pricing />
            </Route>
            <Route path="/register">
              <Register />
            </Route>
            {/* <Route path="/catalogo/:slug/:idProduto">
              <ProductDetail />
            </Route> */}
            <Route exact path="/catalogo/:slug">
              <Catalog />
            </Route>
            <Route exact path="/checkout/:slug">
              <Checkout />
            </Route>
          </Switch>
        </Router>
      </CartProvider>
    </ThemeProvider>
  )
};

export default App;
