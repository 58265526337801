import firebase from "firebase";
import "firebase/storage";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB5BjRq8waBX0hkCf2-_nXGJjZDAyw4V_Q",
  authDomain: "catalogo-online-4756d.firebaseapp.com",
  databaseURL: "https://catalogo-online-4756d.firebaseio.com",
  projectId: "catalogo-online-4756d",
  storageBucket: "catalogo-online-4756d.appspot.com",
  messagingSenderId: "1052682770757",
  appId: "1:1052682770757:web:751e1f372d8594494cc62c",
  measurementId: "G-PLELMYLX10"
};

export const firebaseImpl = firebase.initializeApp(firebaseConfig);
export const firebaseDatabase = firebase.database();
export const firebaseFirestore = firebase.firestore();
export const firebaseStorage = firebase.storage();
export const firebaseAuth = firebase.auth();
