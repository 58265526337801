import { ProductType, CartType } from "../interfaces/products.interface";
import { ProfileType, ClientType } from "../interfaces/user.interface";
import { formatToDateTime, formatToPhone, formatToBRL } from "brazilian-values";

export function string_to_slug(str: String) {
  if (!str) return "";
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
}

export const whatsappMessage = (productProp: ProductType) =>
  `text=${productProp.name} | código:${productProp.cod}`;

export const checkoutWhatsappMessage = (
  profile: ProfileType,
  client: ClientType,
  cart: CartType[],
  cartTotal: number
) =>
  window.encodeURIComponent(`*Novo pedido - ${profile.companyName}*
  
---

*Itens*
${cart
  .map(
    (product) =>
      `${product.quantity} x ${product.name} - ${formatToBRL(
        product.itemTotal
      )}\n`
  )
  .join("")}
\n
total: ${formatToBRL(cartTotal)}

---

*Dados do comprador*
${client.name}
${formatToPhone(client.phone)}

---

*Endereço* 🏡
${client.address}, ${client.number}, 
${client.neighborhood} - CEP: ${client.cep}

---

_Pedido realizado em ${formatToDateTime(new Date())}_`);
