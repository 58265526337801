import React from "react";
import { useCart } from "react-use-cart";
import Badge from '@material-ui/core/Badge';
import { Theme, withStyles, createStyles, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { formatToBRL } from 'brazilian-values';
import history from "../../history";


const StyledBadge = withStyles((theme: Theme) =>
    createStyles({
        badge: {
            right: -3,
            top: 0,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '0 4px',
        },
    }),
)(Badge);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: "fixed",
            bottom: 0,
            right: 0,
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
            alignItems: "center",
            background: theme.palette.background.paper,
            opacity: 0.85
        }
    })
);

export default function BottomCart() {
    const classes = useStyles();
    const {
        isEmpty,
        cartTotal,
        totalUniqueItems
    } = useCart();

    return (
        <Button
            onClick={() => {
                const [, slug] = history.location.pathname.split('/catalogo/')
                history.push(`/checkout/${slug}`)
            }}
            className={classes.container}
            disabled={isEmpty}
        >
            <IconButton aria-label="cart">
                <StyledBadge badgeContent={totalUniqueItems} color="secondary">
                    <ShoppingCartIcon />
                </StyledBadge>
            </IconButton>
            <Typography
                variant="h5"
                align="center"
                color="textSecondary"
                component="p"
            >
                {!isEmpty && "Pedir"}
            </Typography>
            <Typography
                variant="h5"
                align="center"
                color="textSecondary"
                component="p"
            >
                {formatToBRL(cartTotal || 0)}
            </Typography>
        </Button>
    );
}

