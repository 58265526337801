import { firebaseFirestore } from "../utils/firebaseUtils";
import { ProductType } from "../interfaces/products.interface";
import { ProfileType } from "../interfaces/user.interface";
import {
  readLocalStorageJson,
  setLocalStorageJson
} from "../utils/localStorage";

export const saveCatalog = (owner: ProfileType, catalog: ProductType[]) => {
  getMyCatalog();
  return firebaseFirestore.doc(`users/${owner.uid}`).update({ catalog });
};

export const getMyCatalog = async () => {
  const user = readLocalStorageJson("user") as ProfileType;
  const userDoc = await firebaseFirestore.doc(`users/${user.uid}`).get();
  const profile = userDoc.data() as ProfileType;
  setLocalStorageJson("user", profile);
  return profile.catalog;

  // .collection("catalogs")
  // .add({ catalog, updatedAt: new Date() })
  // .update({ catalog: { ...catalog, updatedAt: new Date() } })
  // .then(suc => {
  //   console.log(suc);
  // })
  // .catch(err => {
  //   console.log(err);
  // })
};

export const getCatalogBySlug = async (slug: string) => {
  const catalogs = await firebaseFirestore
    .collection("users")
    .where("slug", "==", slug)
    .get();
  console.log("catalogs", catalogs);
  console.log("slug", slug);
  if (catalogs.size > 0) {
    const [userCatalog] = catalogs.docs;
    const user = userCatalog.data() as ProfileType;
    return user;
  }
  return null;
};
