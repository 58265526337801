import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  signUp,
  handleAuthErrors,
  currentUser,
  updateUserData
} from "../../services/FirebaseAufh";
import ImageUpload from "../../components/UploadImage";
import { ProfileType } from "../../interfaces/user.interface";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "94vh"
  },
  typographContainer: {
    position: "absolute",
    color: "white",
    padding: 20,
    height: "100%",
    background: "black",
    opacity: 0.6
  },
  image: {
    backgroundImage:
      "url(http://www.aprdesigners.com.br/wp-content/uploads/2019/10/criacao-de-catalogo-de-autopecas-iguacu.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100%"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    // margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    padding: theme.spacing(1)
  }
}));

export default function LandingPage() {
  const url = new URL(window.location.href);
  const email = url.searchParams.get("email");
  const name = url.searchParams.get("name");

  const classes = useStyles();
  const [companyName, setCompanyName] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [password, setPassword] = useState("");
  const [companyPhoto, setCompanyPhoto] = useState("");
  const [phone, setPhone] = useState("");
  const [user, setUser] = useState({} as ProfileType);

  const [alertActived, setAlertActived] = useState({
    severity: "success",
    text: "",
    open: false
  } as {
    severity: "success" | "error" | "info" | "warning" | undefined;
    text: string;
    open: boolean;
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertActived({ ...alertActived, open: false });
  };

  async function doRegister() {
    try {
      if (
        companyName &&
        companyDescription &&
        password &&
        companyPhoto &&
        phone &&
        name &&
        email
      ) {
        setIsLoading(true);
        await signUp(email, password, {
          companyDescription,
          name,
          phone,
          email,
          companyName,
          companyPhoto
        });
        setIsLoading(false);
      } else {
        return alert(
          "Preencha todos os dados e coloque uma foto para continuar"
        );
      }
    } catch (error) {
      alert(handleAuthErrors(error));
    }
  }

  async function doUpdate() {
    try {
      if (
        user.uid &&
        companyName &&
        companyDescription &&
        phone
      ) {
        console.log("user: ", user);
        setIsLoading(true);
        await updateUserData(user.uid, {
          ...user,
          companyName: companyName || user.companyName,
          companyDescription: companyDescription || user.companyDescription,
          companyPhoto: companyPhoto || user.companyPhoto,
          phone: phone || user.phone
        });
        setIsLoading(false);
        return setAlertActived({
          text: "Dados atualizados com sucesso!",
          severity: "success",
          open: true
        });
      } else {
        return setAlertActived({
          text: "Preencha todos os dados e coloque uma foto para continuar",
          severity: "info",
          open: true
        });
      }
    } catch (error) {
      console.log("error:", error);
      alert(handleAuthErrors(error));
    }
  }

  async function readUserDataEffect() {
    const userData = await currentUser();
    console.log(userData);
    if (userData) {
      setUser(userData);
      setCompanyName(userData.companyName || "");
      setCompanyDescription(userData.companyDescription || "");
      setCompanyPhoto(userData.companyPhoto || "");
      setPhone(userData.phone || "");
    }
  }

  useEffect(() => {
    readUserDataEffect();
  }, []);

  return (
    <>
      <Grid
        container
        component="main"
        className={classes.root}
        alignContent="center"
        alignItems="center"
        justify="center"
      >
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={10}
          md={10}
          component={Paper}
          elevation={6}
          square
        >
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Informe os dados da sua Empresa
            </Typography>
            <Grid
              alignItems="center"
              alignContent="center"
              container
              className={classes.form}
            >
              <Grid item xs>
                <ImageUpload
                  url={companyPhoto}
                  getFirestoreUrl={(url: string) => setCompanyPhoto(url)}
                />
              </Grid>
              <Grid item xs>
                <form className={classes.form} noValidate>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="companyName"
                    defaultValue={companyName}
                    label="Nome do estabelecimento"
                    name="companyName"
                    autoFocus
                    multiline
                    rowsMax={1}
                    onChange={event => setCompanyName(event.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="companyDescription"
                    defaultValue={companyDescription}
                    label="Descreva seu estabelecimento"
                    id="companyDescription"
                    multiline
                    rowsMax={3}
                    onChange={event =>
                      setCompanyDescription(event.target.value)
                    }
                  />
                  <TextField
                    onChange={event => setPhone(event.target.value)}
                    defaultValue={phone || user.phone}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="phone"
                    label="Whatsapp"
                    placeholder="ex: 71 9 99999999"
                    id="phone"
                    type="tel"
                    rowsMax={1}
                    multiline
                  />
                  {!user.uid && (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      defaultValue={password}
                      label="Uma senha Boa"
                      id="password"
                      type="password"
                      onChange={event => setPassword(event.target.value)}
                    />
                  )}
                  {user.uid ? (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={doUpdate}
                      disabled={isLoading}
                    >
                      {isLoading ? <CircularProgress /> : "Atualizar Perfil"}
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={doRegister}
                      disabled={isLoading}
                    >
                      {isLoading ? <CircularProgress /> : "Cadastrar produtos"}
                    </Button>
                  )}
                </form>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Snackbar
        open={alertActived.open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={alertActived.severity || "error"}
        >
          {alertActived.text}
        </MuiAlert>
      </Snackbar>
    </>
  );
}
